<template>
	<div class="p-4 flex flex-col gap-4 storefinder" :class="isAppDialog ? '' : 'bg-secondary'" ref="storeFinder">
		<div v-html="title" :class="isAppDialog ? 'text-center text-lg font-bold':'text-2xl'"/>
		<template v-if="isAppDialog && useCurrentStore()?.value?.id != 'e_2879130'">
			<div @click="$emit('storeChanged')" class="border-primary border btn-primary p-4 w-full font-bold text-center">
				Danke, die Zuordnung passt,<br/>
				Weiter zur Startseite
			</div>
		</template>
		<div class="grid grid-cols-1 lg:grid-cols-12 gap-4">
			<div class="lg:col-span-5">
				<button class="border-primary border btn-primary p-4 w-full" @click="checkOwnLocation()">
					{{ currentLocationText }}
				</button>
			</div>
			<div class="lg:col-span-7">
				<GMapAutocomplete class="p-4 w-full border" placeholder="Postleitzahl oder Stadt eingeben" :selectFirstOnEnter="true" :options="{
					fields: ['place_id', 'name', 'geometry'], 
					componentRestrictions: { 'country': 'de' },
					types: ['(regions)']
				}" @place_changed="setPlace">
				</GMapAutocomplete>
			</div>
		</div>
		<!-- <storeInformation /> -->
		<div class="grid grid-cols-1 lg:grid-cols-12 gap-4">
			<div :class="(showMap ? 'lg:col-span-5 order-1':'lg:col-span-12 order-3')">
				<PopUpsStoreFinderEntityList class="lg:max-h-[500px] lg:overflow-auto" :storeList="listOfStores"
					@clickedOnStore="clikedOnStore" @storeChanged="$emit('storeChanged')" ref="storeList" />
			</div>
			<div class="lg:col-span-7 lg:order-2 order-0 relative">
				<span v-if="showMap" @click="showMap = !showMap" class="btn-primary absolute z-1 right-1.5 top-1.5 text-3xl font-bold px-5 py-1 cursor-pointer">-</span>
				<a v-else @click="showMap = !showMap" class="text-primary cursor-pointer">Karte anzeigen</a>
				<GMapMap :center="center" map-type-id="terrain" :zoom="zoom" class="w-full" style="height: 500px" :class="(showMap ? '':'hidden')">
					<GMapMarker :key="index" v-for="(marker, index) in markers"
						:icon="'https://cdn.expert.de/static/images/test/pin.png'" :position="marker.position"
						@click="storeList.selectStore(marker)" :clickable="true" />
				</GMapMap>
			</div>
		</div>
		<div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
			<div class="text-xs">
				Sie können Ihren Fachmarkt finden, indem Sie unter Nutzung Ihrer IP-Adresse und
				anderer technischer Daten Ihren aktuellen Standort ermitteln lassen oder eine
				Postleitzahl / Stadt eingeben. Wenn Sie diese Möglichkeiten nicht nutzen möchten,
				ermitteln wir automatisiert Ihren ungefähren Standort, um Sie einem Fachmarkt
				zuordnen zu können. Dies ist für die Nutzung unserer Website / Shop notwendig.
				Weitere Infos in der Datenschutzerklärung
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { useGeolocation } from '@vueuse/core';
import VueGoogleMaps from 'vue-google-maps-community-fork';
const props = defineProps({
	title: {
		type: String,
		default: 'Jetzt einen Fachmarkt zuordnen!'
	},
	isAppDialog: {
		type: Boolean,
	}
})

const currentLocationText = ref("Aktuellen Standort ermitteln");
const storeList = ref(null)
const zoom = ref(8);
const showMap = ref(true);

const emit = defineEmits(['storeChanged']);

const config = useRuntimeConfig()

function clikedOnStore(storeClickedOn:any) {
	zoom.value = 8;
	center.value = {
		lat: 0,
		lng: 0
	}
    nextTick(async () => {
		zoom.value = 11;
		center.value = {
			lat: parseFloat(storeClickedOn?.latitude),
			lng: parseFloat(storeClickedOn?.longitude)
		}
    });
}

const app = useNuxtApp().vueApp;
app
	.use(VueGoogleMaps, {
		load: {
			key: config.public.googleApiKey,
			libraries: "places"
		},
	})
	.mount('#app')

const center = ref({ lat: 0, lng: 0 });
const markers = ref([{ id: 'id123', position: { lat: 0, lng: 0 } }])

const searchPhrase = ref()
const searchPosition = ref(useCurrentStore()?.value?.position);
const searchUserPosition = ref();
const maxResults = ref(10)
const displayNearbyStores = ref(true);



const { data: listOfStores } = await useFetch(useRuntimeConfig().public.apiUrl + '/api/storeFinder', {
	params: {
		maxResults: maxResults,
		searchPhrase: searchPhrase,
		position: searchPosition
	}, 
	method: 'GET',
	key: Date.now().toString()
});

watch(listOfStores, () => {
	center.value = {
		lat: parseFloat(listOfStores?.value?.at(0)?.store?.latitude),
		lng: parseFloat(listOfStores?.value?.at(0)?.store?.longitude)
	}
	markers.value = new Array();
	listOfStores.value?.forEach(store => {
		let marker = {
			id: store.store.id,
			position: {
				lat: parseFloat(store.store.latitude),
				lng: parseFloat(store.store.longitude)
			}
		}
		markers.value.push(marker);
	})
}, {
	immediate: true
});

function setPlace(param: any) {
	displayNearbyStores.value = false;
	searchPhrase.value = param.name;
	searchPosition.value = {
		lng: param.geometry.location.lng(),
		lat: param.geometry.location.lat()
	};
}

const options = {
	immediate : false
};

const { coords, error, resume, pause, locatedAt } = useGeolocation(options);

function checkOwnLocation() {
	resume();
	displayNearbyStores.value = true;
	currentLocationText.value = "Standort wird ermittelt...";
}

watch(coords, (someVal) => {
	console.debug('[bt-storeFinder] coords changed', someVal);
	if (!error?.value) {
		searchPosition.value = {
			lng: someVal.longitude,
			lat: someVal.latitude
		};
		console.debug('[bt-storeFinder] from ', coords.value, 'to', someVal, "locatedAt", locatedAt?.value);
		let locd = new Date(locatedAt?.value || Date.now());
		currentLocationText.value = "Aktueller Standort zuletzt um " + locd.getHours() + ':' + (locd.getMinutes() < 10 ? '0' : '') + locd.getMinutes() + " ermittelt";
		searchPhrase.value = undefined;
	} else {
		currentLocationText.value = "Konnte Standort nicht ermitteln. " + error?.value?.message;
		searchPhrase.value = undefined;
	}
	displayNearbyStores.value = true;
	pause();
});
</script>