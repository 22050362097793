import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';

import version from "~/package.json";
export default defineNuxtPlugin((_nuxtApp) => {
  // if (import.meta.client) {
  if (!useIsCrawlerState()?.value) {
    const faro = initializeFaro({
      url: "https://grafana-agent.cuboxx.io/collect",
      apiKey: "braintags0815",
      app: {
        name: "vue-expert-shop",
        release: useRuntimeConfig()?.public?.version?.branch,
        version: "" + version?.version + ".b" + useRuntimeConfig()?.public?.version?.build,
        environment: useRuntimeConfig()?.public?.version?.environment || 'localhost'
      },
      instrumentations: [
        ...getWebInstrumentations({
          enablePerformanceInstrumentation: true,
        }),
      ],
      user: {
        attributes: {
          "type": "frontend"
        },
      },
      sessionTracking: { enabled: false },
    });

    return {
      provide: {
        faro: faro,
      },
    };
  }
  // }
  // }
});
