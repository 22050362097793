import { toValue } from 'vue'
import { ExpStore } from 'models/ExpLocalStore/exp_Localstore';
import { Price } from 'models/ExpPrice/exp_Price';
import { getLocalExpStore } from './expLocalStore';
/**
 * Get the Price
 * @date 11.7.2023 - 09:54:37
 *
 * @export
 * @async
 * @param {string} jwtString
 * @param {string} articleId
 * @param {string} store
 * @returns {}
 */
export const getPrice = (articleId: string, store: Ref<ExpStore>| Ref<String>, liveCheck: boolean, lazy?: boolean) => {
	console.log('[bt-expPrice] fetch price for store ' + toValue(store), " ;:  ", articleId, "  ; ;  ", (store?.value?.storeId ?? store?.value));
	let query: any = {};
	if (articleId?.startsWith("e_")) {
		query["articleId"] = articleId;
	} else {
		query["webcode"] = articleId;
	}
	query["storeId"] = store?.value?.id ?? store?.value;
	if (liveCheck)
		query["cacheLevel"] = "LIVE";
	return useFetch(
		useRuntimeConfig().public.apiUrl + '/api/pricepds',
		{
			key: "pricepds/webcode=" + articleId + ";storeId=" + (store?.value?.id ?? store?.value),
			query: query,
			server: false,
			credentials: 'include',
			referrerPolicy: 'no-referrer-when-downgrade',
			dedupe: 'defer',
			lazy: lazy ? lazy : true,
			watch: [store]
		});
}
