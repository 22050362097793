<template>
  <template v-if="!pending && localprice !== undefined">
    <div
      v-if="showBasic || promotionArticle?.checkoutPrice === undefined || ((promotionArticle?.checkoutPrice === promotionArticle?.currentPrice) && (promotionArticle?.afterCashbackPrice === promotionArticle?.currentPrice))">
      <ArticleExpPriceBasic class="col-start-1"
        :localarticle="localarticle" :pprice="pprice"
        :discountedPrice="promotionArticle?.checkoutPrice"
        :showDataFile="showDataFile"
        :showShippingEstimate="showShippingEstimate"
        :showEEL="showEEL"
        :lineBreakDeliveryInfo="lineBreakDeliveryInfo"
        :addsReserveSpace="addsReserveSpace"
        :displayPriceDesignClasses="displayPriceDesignClasses"
        :deliveryInfoClass="deliveryInfoClass"
        :formerPriceClass="formerPriceClass"
        :popOverClass="popOverClass"
        :showShipmentInformation="showShipmentInformation"
        :enableDeliveryModal="enableDeliveryModal"
        :enableUVPLabel="enableUVPLabel"
        :enableUVPFlyOut="enableUVPFlyOut"
        :enableUVPFlag="enableUVPFlag"
        :exhibitInfo="exhibitInfo"
        :uvpTextField="uvpTextField"
        :showOnlinePriceInfo="showOnlinePriceInfo" />
    </div>
    <div class="flex flex-col gap-2 exp-price w-full"
      v-else>
      <template
        v-if="localprice.itemOnDisplay && localprice.itemOnDisplayDescription !== null">
        <div class="flex">
          <img class="articleExhibit mr-2"
            alt="Austeller Info"
            src="https://cdn.expert.de/53/d6/3c/4013451a75c98791c284c0f24b8da8c883/exhibit.png" />
          <img v-if="exhibitInfo" class="ic-icon"
            @click.prevent="!$device.isDesktop ? (itemOnDisplayHover = !itemOnDisplayHover) : false"
            @mouseleave="itemOnDisplayHover = false"
            @mouseover="itemOnDisplayHover = true"
            src="~/assets/mediums/Icons/circle-info-light.svg"
            alt="Aussteller Info" style="min-height: 25" />
        </div>
        <div v-if="itemOnDisplayHover == true"
          class="exhibition-hover-info border z-20"
          :class="popOverClass">
          {{ localprice.itemOnDisplayDescription }}
        </div>
      </template>
      <div class="flex">
        <ArticleEnergyEfficiency v-if="showEEL"
          class="text-sm" :showDataFile="false"
          :article="localarticle"
          labelStyles="max-height:34px;min-height: 34px;" />
      </div>
      <template v-if="
        localarticle?.formerPriceState !== '' &&
        localarticle?.formerPriceState !== null &&
        enableUVPLabel &&
        formerPriceDifferenceText?.length > 2 &&
        localarticle?.formerBruttoPrice > localprice.bruttoPrice
      ">

        <div class="flex gap-5">
          <div class="formerPrice formerPrice-comp">
            <span class="strike-through">
              &nbsp;{{ localarticle?.formerPriceState
              }}&nbsp;{{
                localarticle?.formerBruttoPrice
              }}€
            </span>
          </div>
          <div class="uvp-info relative flex">
            <img class="w-5"
              alt="Unverbindliche Preis Empfehlung"
              src="~/assets/mediums/Icons/circle-info-light.svg"
              @mouseleave="showUvpTextField = false"
              @mouseover="showUvpTextField = true"
              @click.prevent="!$device.isDesktop ? (showUvpTextField = !showUvpTextField) : false" />
            <div v-if="showUvpTextField"
              class="uvp-hover-info whitespace-nowrap">
              UVP des Herstellers
            </div>
          </div>
          <div
            class="sales-label pl-2 flex items-end flex-grow flex-col">
            <div>
              <div
                v-if="formerPriceDifferenceText !== undefined && formerPriceDifferenceText !== null">
                <div class="percent-discount">{{
                  formerPriceDifferenceText }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template
        v-if="promotionArticle?.directDiscount !== undefined && promotionArticle?.directDiscount !== 0">
        <div class="flex">
          <span class="our-price-label">Unser Preis</span>
          <div
            class="our-price-value flex items-end flex-grow flex-col">
            <span class="our-price">
              <ArticlePubDataWrapper class="exp_price"
                :createdOn="localprice?.createdOn"
                :lastUpdated="localprice?.lastUpdated"
                :cacheLevel="localprice?.cacheLevel"
                :pubSource="localprice?.pubSource">
                {{
                  (localprice.bruttoPrice % 1 === 0
                    ? localprice.bruttoPrice + ".-"
                    :
                    parseFloat(localprice.bruttoPrice).toFixed(2))
                  +
                  (localprice.monthly ? " pro Monat" : "")
                }}
              </ArticlePubDataWrapper>
            </span>
          </div>
        </div>
      </template>
      <div class="flex"
        v-if="promotionArticle?.directDiscount !== undefined && promotionArticle?.directDiscount !== 0">
        <span
          class="immediate-discount-label">sofort-rabatt*</span>
        <div
          class="immediate-discount-value flex items-end flex-grow flex-col">
          <span class="immediate-discount">
            {{ promotionArticle.directDiscount % 1 === 0
              ? promotionArticle.directDiscount + ".-"
              : promotionArticle.directDiscount.toFixed(2) }}
          </span>
        </div>
      </div>
      <div class="flex checkout-price-gradient" v-if="
        promotionArticle?.checkoutPrice !== undefined &&
        promotionArticle?.checkoutPrice !== 0">
        <span class="checkout-price-label">Dein Preis <br />
          an
          der Kasse</span>
        <span class="checkout-price-value">
          {{
            (promotionArticle.checkoutPrice % 1 === 0
              ? promotionArticle.checkoutPrice + ".-"
              : promotionArticle.checkoutPrice.toFixed(2))
          }}
        </span>
      </div>
      <div class="flex" v-if="
        promotionArticle?.cashbackDiscount !== undefined &&
        promotionArticle?.cashbackDiscount !== 0">
        <span class="cashback-provider-label">{{
          promotionArticle.cashbackTitle }}*</span>
        <div
          class="cashback-provider-value flex items-end flex-grow flex-col">
          <span class="cashback-provider">
            {{
              (promotionArticle.cashbackDiscount % 1 === 0
                ? promotionArticle.cashbackDiscount + ".-"
                : promotionArticle.cashbackDiscount.toFixed(2))
            }}
          </span>
        </div>
      </div>
      <div>
        <hr class="price-divider" />
      </div>
      <div class="flex" v-if="
        promotionArticle?.afterCashbackPrice !== undefined &&
        promotionArticle?.afterCashbackPrice !== 0 &&
        promotionArticle?.cashbackDiscount !== undefined &&
        promotionArticle?.cashbackDiscount !== 0">
        <span class="after-cashback-label">Dein Preis nach
          Cashback</span>
        <div
          class="after-cashback-value flex items-end flex-grow flex-col">
          <span class="after-cashback">
            {{
              promotionArticle.afterCashbackPrice % 1 === 0
                ? promotionArticle.afterCashbackPrice + ".-"
                : promotionArticle.afterCashbackPrice.toFixed(2)
            }}
          </span>
        </div>
      </div>
      <div class="additional-info flex flex-col gap-1">

        <ArticleDeliveryInfo
          :enableDeliveryModal="enableDeliveryModal"
          :showShipmentInformation="showShipmentInformation"
          :pprice="pprice" :showModal="showModal"
          :deliveryInfoClass="deliveryInfoClass"
          :showShippingEstimate="showShippingEstimate"
          :addsReserveSpace="addsReserveSpace"
          :localprice="localprice"
          :lineBreakDeliveryInfo="lineBreakDeliveryInfo"
          :shipmentBruttoPrice="(localprice.shipmentArray?.length > 0 ? localprice.shipmentArray[0].shipmentBruttoPrice : null)" />

        <div v-if="!pending" class="afterprice">
          <slot name="afterPrice" :finalPrice="localprice">
          </slot>
          <ArticleExpStore v-if="localprice?.showStoreName"
            :storeId="localprice?.showStoreName">
          </ArticleExpStore>
        </div>
        <div
          v-if="
            (promotionArticle?.cashbackDiscount !== undefined &&
              promotionArticle?.cashbackDiscount !== 0) ||
            (promotionArticle?.directDiscount !== 0 &&
              promotionArticle?.directDiscount !== undefined)"
          class="discount-advice text-xs">
          <a href="#a-nav-5"
            @click="clickAnchorForAccordionPanel($event, 'a-nav-5')"
            class="underline">
            *Alle Informationen zu den Aktionen
          </a>
        </div>
      </div>
    </div>
  </template>
</template>

<script
  setup
  lang="ts"
>
import { getPrice } from "~/composables/ExpApi/expPrice";
import { clickAnchorForAccordionPanel } from "~/composables/miscFunctions";
import type { ArticlePromotions } from "~/models/Promotions";

interface Props {
  localarticle?: any,
  lineBreakDeliveryInfo?: boolean,
  showEEL?: boolean
  addsReserveSpace?: boolean,
  deliveryInfoClass?: string,
  formerPriceClass?: string,
  displayPriceDesignClasses?: string,
  popOverClass?: string,
  pprice?: any,
  showShipmentInformation?: boolean,
  enableDeliveryModal?: boolean,
  enableUVPLabel?: boolean,
  enableUVPFlyOut?: boolean,
  enableUVPFlag?: boolean,
  exhibitInfo?: boolean,
  uvpTextField?: boolean,
  showOnlinePriceInfo?: boolean,
  showDataFile?: boolean,
  showShippingEstimate?: boolean,
  showBasic?: boolean,
  promotionPrice?: ArticlePromotions,
  fetchPrice?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  lineBreakDeliveryInfo: false,
  showEEL: false,
  addsReserveSpace: false,
  deliveryInfoClass: "",
  formerPriceClass: "flex gap-2 flex-wrap relative items-center",
  displayPriceDesignClasses: "text-5xl relative flex gap-4",
  popOverClass: "w-[250px]",
  showShipmentInformation: true,
  enableDeliveryModal: true,
  enableUVPLabel: true,
  enableUVPFlyOut: true,
  enableUVPFlag: true,
  exhibitInfo: true,
  uvpTextField: false,
  showOnlinePriceInfo: true,
  showDataFile: false,
  showShippingEstimate: false,
  showBasic: false,
  fetchPrice: false
});

const localprice = ref(props?.pprice);
watch(() => props?.pprice, () => {
  localprice.value = props?.pprice;
})
const pending = ref(true);

const itemOnDisplayHover = ref(false);
const showUvpTextField = ref(false);
const showModal = ref(false);

const minPercentDifference = 10;
const maxPercentDifference = 70;
const minPriceForPercentage = 50;
const minAbsolutDifference = 5;

const promotionArticle: Ref<ArticlePromotions> = ref(
  {
    currentPrice: 0,
    directDiscount: 0,
    checkoutPrice: 0,
    cashbackDiscount: 0,
    cashbackTitle: "",
    afterCashbackPrice: 0,
  }
);

watch(() => props?.promotionPrice, () => {
  if (props?.promotionPrice)
    promotionArticle.value = props.promotionPrice;
})

if ((props.pprice === null || props.pprice === undefined) && props?.fetchPrice) {
  console.debug("fetching price because none was given", props?.localarticle, props.pprice);
  const { data: neuerPreis } = await getPrice(
    props.localarticle.webcode,
    useCurrentStore(),
    false,
    false
  );
  pending.value = false;
  if (neuerPreis.value) localprice.value = neuerPreis.value?.price;
} else {
  pending.value = false;
}

const formerPriceDifferenceText = computed(() => {
  let calculation = 100 - (localprice?.value?.bruttoPrice / props.localarticle?.formerBruttoPrice) * 100;
  let result = calculation.toFixed(0);
  if (localprice?.value?.bruttoPrice >= minPriceForPercentage) {
    if (calculation > minPercentDifference && calculation < maxPercentDifference)
      return "-" + result + "%";
  } else {
    if (
      props.localarticle?.formerBruttoPrice - localprice?.value?.bruttoPrice >= minAbsolutDifference &&
      calculation < maxPercentDifference
    )
      return "-" + result + "%";
  }
});
</script>

<style
  lang="css"
  scoped
>
.exp-price .percent-discount {
  background-color: #005bab;
  color: white;
  padding: 0px 10px;
  font-family: foundationsans-blackcd, sans-serif;
  font-style: italic;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.exp-price .our-price-label {
  align-content: center;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.exp-price .our-price-value {
  display: flex;
  align-items: flex-end;
  font-family: foundationsans-blackcd, sans-serif;
  font-style: italic;
  color: #005bab;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.exp-price .our-price {
  display: flex;
  align-items: flex-end;
  font-family: foundationsans-blackcd, sans-serif;
  font-style: italic;
  color: #005bab;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.exp-price .immediate-discount-label {
  align-content: center;
  text-transform: uppercase;
  font-style: italic;
  color: rgb(var(--color-primary));
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.exp-price .immediate-discount {
  font-family: foundationsans-blackcd, sans-serif;
  font-style: italic;
  background-color: rgb(var(--color-primary));
  color: white;
  padding: 0 10px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.exp-price .checkout-price-gradient {
  background-image: linear-gradient(to right, #005bab, #04a1e5, #005bab);
  padding: 5px 10px;
}

.exp-price .checkout-price-gradient>* {
  flex: 1 1 0;
}

.exp-price .checkout-price-label {
  align-content: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  flex: 1 1 0;
  line-height: 0.9rem;
  font-size: 0.75rem;
}

.exp-price .checkout-price-value {
  color: white;
  font-weight: 400;
  font-family: foundationsans-blackcd, sans-serif;
  text-align: right;
  font-size: 2rem;
  line-height: 2rem;
  font-style: italic;
  flex: 1 1 0;
}

.exp-price .cashback-provider-label {
  align-content: center;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  flex: 1 1 auto;
  font-size: 0.75rem;
  line-height: 0.75rem;
  flex: 1 1 0;
}

.exp-price .cashback-provider-value {
  display: flex;
  align-items: flex-end;
  flex: 1 1 0;
}

.exp-price .cashback-provider {
  font-weight: 400;
  font-family: foundationsans-blackcd, sans-serif;
  font-style: italic;
  color: #005bab;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.exp-price hr.price-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid black;
  padding: 0;
}

.exp-price .after-cashback-label {
  align-content: center;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.75rem;
  flex: 1 1 0;
}

.exp-price .after-cashback-value {
  display: flex;
  align-items: flex-end;
  flex: 1 1 0;
}

.exp-price .after-cashback {
  font-weight: 400;
  font-family: foundationsans-blackcd, sans-serif;
  color: #005bab;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-style: italic;
  flex: 1 1 0;
}
</style>