<template>
  <NuxtErrorBoundary @error="someErrorLogger">
    <template #error="{ error, clearError }">
      <div class="flex flex-col gap-y-4 p-4 lg:p-0">
        <div class="container mx-auto py-5 flex text-paragraph font-bold justify-between text-center">
          <div class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded self-bottom">
            <button @click="clearError()">
              <strong>Fehler Aufgetreten.</strong> 
              Zurücksetzen und erneut versuchen.
            </button>
          </div>
        </div>
      </div>
    </template>
    <slot />

  </NuxtErrorBoundary>
</template>
<script setup lang="ts">
function someErrorLogger(error: any) {
  try {
    console.error("[bt-boundary] Some error occured: ", JSON.stringify(error));
    if(error?.fatal) {
      console.warn('got fatal error')
      throw error;
    }
    if (error?.toString().includes('TypeError: Cannot read properties of null') || (error?.toString().includes('bum') && error?.toString().includes('TypeError'))) {
      console.debug('trying to clear TypeError on my own', error);
      // window.location.reload();
    }

  } catch (error) {
    throw createError({ statusCode: 500, statusMessage: 'Page Broken', cause: error, fatal: true })

    console.error("[bt-default] could not log the error", error);
  }
}

</script>